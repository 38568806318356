import { template as template_36eb9a20de004c419949410eb9d80f4f } from "@ember/template-compiler";
const FKLabel = template_36eb9a20de004c419949410eb9d80f4f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
