import { template as template_debe69a40bcc44a4af9f86e60e0d4a27 } from "@ember/template-compiler";
const FKText = template_debe69a40bcc44a4af9f86e60e0d4a27(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
