import { template as template_f0eb47b51dca4f2f901c93289ce0ac8b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_f0eb47b51dca4f2f901c93289ce0ac8b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
