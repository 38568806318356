import { template as template_6be1892183c640b6904ea164a792eec5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_6be1892183c640b6904ea164a792eec5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
